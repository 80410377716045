


















































































































































import { TrainingGroundViewmodel } from "../viewmodels/training-ground-viewmodel";
import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref } from "vue-property-decorator";
import { walletStore } from "@/stores/wallet-store";
import { blockchainHandler } from "@/blockchain/index";
import { TrainingGroundEventViewmodel } from "@/modules/training-ground-event/viewmodels/training-ground-event-viewmodel";

@Observer
@Component({
  components: {
    Countdown: () => import("@/components/Countdown.vue"),

    // event
    IncreaseUnlockTimeDialogEvent: () =>
      import("@/modules/training-ground-event/dialogs/increase-unlock-time-dialog.vue"),
    IncreaseStakeDialogEvent: () =>
      import("@/modules/training-ground-event/dialogs/increase-stake-dialog.vue"),
    SelectMonsterEvent: () => import("@/modules/training-ground-event/components/select-monster.vue"),
    SelectMonsterDialogEvent: () =>
      import("@/modules/training-ground-event/dialogs/select-monster-dialog.vue"),
    NoStakeMonsterDialogEvent: () =>
      import("@/modules/training-ground-event/dialogs/no-stake-monster-dialog.vue"),
    UnstakeMonsterDialogEvent: () =>
      import("@/modules/training-ground-event/dialogs/unstake-monster-dialog.vue"),
    UnstakeDialogEvent: () => import("@/modules/training-ground-event/dialogs/unstake-dialog.vue"),
    APRDetailEvent: () => import("@/modules/training-ground-event/components/APRDetail.vue"),
    FirstStakeCardEvent: () => import("@/modules/training-ground-event/components/no-stake-card.vue"),
    StakedCardEvent: () => import("@/modules/training-ground-event/components/staked-card.vue"),

    // normal
    IncreaseUnlockTimeDialog: () => import("../dialogs/increase-unlock-time-dialog.vue"),
    IncreaseStakeDialog: () => import("../dialogs/increase-stake-dialog.vue"),
    SelectMonster: () => import("../components/select-monster.vue"),
    SelectMonsterDialog: () => import("../dialogs/select-monster-dialog.vue"),
    NoStakeMonsterDialog: () => import("../dialogs/no-stake-monster-dialog.vue"),
    UnstakeMonsterDialog: () => import("../dialogs/unstake-monster-dialog.vue"),
    UnstakeDialog: () => import("../dialogs/unstake-dialog.vue"),
    APRDetail: () => import("../components/APRDetail.vue"),
    FirstStakeCard: () => import("../components/no-stake-card.vue"),
    StakedCard: () => import("../components/staked-card.vue"),
  },
})
export default class TrainingGround extends Vue {
  @Provide() vm = new TrainingGroundViewmodel();
  @Provide() vmEvent = new TrainingGroundEventViewmodel();
  tab = 0;
}

/* eslint-disable prettier/prettier */
import { FixedNumber } from "@ethersproject/bignumber";
import { walletStore } from "@/stores/wallet-store";
import { loadingController } from "@/components/global-loading/global-loading-controller";
import { blockchainHandler } from "@/blockchain";
import moment from "moment";
import Web3 from "web3";
import { asyncAction } from "mobx-utils";

//TODO: Change to mainnet
const web3 = blockchainHandler.getWeb3(process.env.VUE_APP_CHAIN_ID)!;

async function sendRequest(fx, from) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on("receipt", (result) => resolve(result))
      .on("error", (error) => reject(error));
  });
}

export class TraningGroundHandler {
  LPTokenContract: any;
  nftContract?: any;
  trainingContract?: any;

  lockedAmount?: FixedNumber;
  rewardToken?: string;

  constructor() {
    this.LPTokenContract = new web3.eth.Contract(
      require("./erc20.abi.json"),
      process.env.VUE_APP_TOKEN_ADDRESS
    );
    this.nftContract = new web3.eth.Contract(require("./erc721.abi.json"), process.env.VUE_APP_NFT_ADDRESS);
    this.trainingContract = new web3.eth.Contract(
      require("./training-ground.abi.json"),
      process.env.VUE_APP_TRAINING_ADDRESS
    );
  }

  @asyncAction *load() {
    try {
      const rewardToken = yield this.trainingContract.methods.demoleToken().call();
      this.rewardToken = web3.utils.toChecksumAddress(rewardToken);

      this.LPTokenContract = new web3.eth.Contract(require("./erc20.abi.json"), this.rewardToken);
      const { lockedAmount } = yield this.getTotalLockedAmount();
      this.lockedAmount = lockedAmount;
    } catch (error) {
      console.error(error);
    }
  }

  injectMetamask(web3: Web3) {
    if (web3) {
      this.LPTokenContract = new web3.eth.Contract(
        require("./erc20.abi.json"),
        process.env.VUE_APP_TOKEN_ADDRESS
      );
      this.nftContract = new web3.eth.Contract(require("./erc721.abi.json"), process.env.VUE_APP_NFT_ADDRESS);
      this.trainingContract = new web3.eth.Contract(
        require("./training-ground.abi.json"),
        process.env.VUE_APP_TRAINING_ADDRESS
      );
    }
  }

  async approved(account) {
    try {
      const allowance = await this.LPTokenContract.methods
        .allowance(account, process.env.VUE_APP_TRAINING_ADDRESS)
        .call();
      return !!+web3.utils.fromWei(allowance);
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async approve(account) {
    const f = this.LPTokenContract.methods.approve(
      process.env.VUE_APP_TRAINING_ADDRESS,
      web3.utils.toWei(`${2 ** 64 - 1}`)
    );
    await sendRequest(f, account);
  }

  async approvedNFT(account) {
    try {
      return await this.nftContract.methods
        .isApprovedForAll(account, process.env.VUE_APP_TRAINING_ADDRESS)
        .call();
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async approveNFT(account) {
    const f = this.nftContract.methods.setApprovalForAll(process.env.VUE_APP_TRAINING_ADDRESS, true);
    await sendRequest(f, account);
  }

  async harvest(account) {
    const f = this.trainingContract.methods.harvest();
    await sendRequest(f, account);
  }

  async getTotalLockedAmount() {
    const amount = await this.trainingContract.methods.totalAmount().call();
    const lockedAmount = FixedNumber.from(`${web3.utils.fromWei(amount)}`);
    return { lockedAmount };
  }

  async getUserInfo(account) {
    const {
      0: amount,
      1: rewardAmount,
      2: extraRewardAmount,
      3: lastStakeTime,
      4: lockDuration,
      5: normalNo,
      6: rareNo,
      7: lastRewardTime,
    } = await this.trainingContract.methods.userInfos(account).call();
    const [userExtraBonus, userApy, userPendingReward] = await Promise.all([
      this.trainingContract.methods.getUserExtraBonus(account).call(),
      this.trainingContract.methods.getUserApy(account).call(),
      this.trainingContract.methods.getUserPendingReward(account).call(),
    ]);
    return {
      amount: FixedNumber.from(`${web3.utils.fromWei(amount)}`),
      lastStakeTime: +lastStakeTime ? moment(+lastStakeTime * 1000) : null,
      lastRewardTime: +lastRewardTime ? moment(+lastRewardTime * 1000) : null,
      rewardAmount: FixedNumber.from(`${web3.utils.fromWei(rewardAmount)}`),
      extraRewardAmount: FixedNumber.from(`${web3.utils.fromWei(extraRewardAmount)}`),
      lockDuration: moment.duration(lockDuration, "second"),
      normalNo,
      rareNo,
      userExtraBonus: FixedNumber.from(`${web3.utils.fromWei(userExtraBonus)}`),
      userApy: FixedNumber.from(`${web3.utils.fromWei(userApy)}`),
      userPendingReward: FixedNumber.from(`${web3.utils.fromWei(userPendingReward)}`),
    };
  }
  async getTotalLockedMonster() {
    return await this.trainingContract.methods.totalNft().call();
  }
  async getUserLockedNfts(account) {
    return await this.trainingContract.methods.getUserLockedNfts(account).call();
  }
  async getUserLPBalance(account) {
    const allowance = await this.LPTokenContract.methods.balanceOf(account).call();
    return FixedNumber.from(`${web3.utils.fromWei(allowance)}`);
  }
  async getAPYConfigs() {
    return await this.trainingContract.methods.apyConfigs(1).call();
  }

  async stake(account, amount, lockDuration, nftIds) {
    const f = this.trainingContract.methods.deposit(
      web3.utils.toWei(`${amount.toString()}`),
      lockDuration,
      nftIds
    );
    await sendRequest(f, account);
  }

  async lockNFTs(account, nftIds) {
    const f = this.trainingContract.methods.lockNfts(nftIds);
    await sendRequest(f, account);
  }

  async increaseLockTime(account, lockDuration) {
    const f = this.trainingContract.methods.deposit(0, lockDuration, []);
    await sendRequest(f, account);
  }
  async increaseStake(account, amount, lockDuration) {
    const f = this.trainingContract.methods.deposit(
      web3.utils.toWei(`${amount.toString()}`),
      lockDuration,
      []
    );
    await sendRequest(f, account);
  }
  async withdrawStake(account) {
    const f = this.trainingContract.methods.withdraw();
    await sendRequest(f, account);
  }
  async withdrawNFTs(account) {
    const f = this.trainingContract.methods.withdrawNfts();
    await sendRequest(f, account);
  }
}

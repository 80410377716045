import { render, staticRenderFns } from "./training-ground.vue?vue&type=template&id=237f069c&scoped=true&"
import script from "./training-ground.vue?vue&type=script&lang=ts&"
export * from "./training-ground.vue?vue&type=script&lang=ts&"
import style0 from "./training-ground.vue?vue&type=style&index=0&id=237f069c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "237f069c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VImg,VTab,VTabItem,VTabs,VTabsItems})

import { chunk, isEmpty } from "lodash-es";
import { TraningGroundHandler } from "./../../../helpers/training-ground-helper";
import { numberHelper } from "./../../../helpers/number.hepler";
import { moralisHelper } from "@/helpers/moralis-helper";
import { localdata } from "@/helpers/local-data";
import { snackController } from "@/components/snack-bar/snack-bar-controller";
import { bigNumberHelper } from "@/helpers/bignumber-helper";
import { walletStore } from "@/stores/wallet-store";
import { FixedNumber } from "@ethersproject/bignumber";
import { action, computed, IReactionDisposer, observable, reaction, runInAction, when } from "mobx";
import { asyncAction } from "mobx-utils";
import moment from "moment";
import { StakeBoosters, StakeLockPeriods, Zero } from "@/constants";
import { Subject, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { priceHelper } from "../../../helpers/pancakePriceHelper";
import { loadingController } from "@/components/global-loading/global-loading-controller";
import { appProvider } from "@/app-providers";
import { NftModel } from "@/models/nft-model";
import { MoralisModel } from "@/models/moralis-model";
import { VMain } from "vuetify/lib";
import { promiseHelper } from "@/helpers/promiseHelper";

export class TrainingGroundViewmodel {
  _disposers: IReactionDisposer[] = [];
  private _unsubcrible = new Subject();

  @observable isDialogLoading = false;
  @observable tokenPrice = FixedNumber.from("0");

  @observable increaseUnlockTimeDialog = false;
  @observable increaseStakeDialog = false;
  @observable selectMonsterDialog = false;
  @observable unstakeDialog = false;
  @observable unstakeMonsterDialog = false;
  @observable noStakeMonsterDialog = false;

  @observable noStakeMonsterDone = false;

  @observable approved = false;
  @observable approving = false;
  @observable nftApproved = false;
  @observable nftApproving = false;
  @observable isStaking = false;
  @observable isHarvesting = false;

  @observable stakedLP = FixedNumber.from("0");
  @observable userLPBalance = FixedNumber.from("0");
  @observable lastStakeTime: moment.Moment | null = null;
  @observable totalLockedAmount = FixedNumber.from("0");
  @observable totalValueLocked = FixedNumber.from("0");
  @observable totalLockedMonster = 0;
  @observable userLockedDuration = moment.duration(0, "second");
  @observable userLockedNormalNo = 0;
  @observable userLockedRareNo = 0;
  @observable userExtraBonus = FixedNumber.from("0");
  @observable userApy = FixedNumber.from("0");
  @observable userPendingReward = FixedNumber.from("0");
  @observable userLockedMonsters: NftModel[] = [];

  @observable lastHarvestAmount = FixedNumber.from("0");

  @observable currentLockPeriod: any = null;
  @observable selectedAPR = 0;
  @observable selectedLockPeriod = "-/--";
  @observable inputStakeAmount = "0";
  @observable myMonsters = {};
  @observable selectingMonsterObj = {};
  @observable selectedMonsterObj = {};
  @observable myNFTs: any[] = [];
  @observable totalOwnedMonsters = 0;
  @observable totalOwnedMonsterPage = 0;
  @observable ownedMonsterPage = 1;
  @observable monstersPerPage = 5;
  @observable loadingMonster = false;
  @observable isLockMonster = false;

  @observable slicedMyMonsters: NftModel[] = [];

  loaded = false;
  searchParams: any = {};
  totalFilteredMonster = 0;
  filteredMonsters: NftModel[] = [];

  trainingGroundHandler?: TraningGroundHandler;

  constructor() {
    this.loadData();
    this.loaded = true;
    this._disposers = [
      reaction(
        () => walletStore.account,
        () => {
          if (walletStore.isChainIdValid) {
            this.trainingGroundHandler?.injectMetamask(walletStore.web3!);
            if (!this.loaded) {
              this.loadData();
              this.loaded = true;
            }
          }
        }
      ),
    ];
  }

  destroy() {
    this._unsubcrible.next();
    this._unsubcrible.complete();
    this._disposers.forEach((d) => d());
  }

  @action.bound searchMyMonsters(params) {
    this.searchParams = params;
    if (isEmpty(this.searchParams)) {
      this.totalFilteredMonster = this.totalOwnedMonsters;
      this.filteredMonsters = Object.values(this.myMonsters);
    } else {
      this.ownedMonsterPage = this.searchParams.page || this.ownedMonsterPage;
      let monsters = this.myMonsterArray as any;
      if (this.searchParams.id) monsters = monsters.filter((monster) => monster.ID.includes(this.searchParams.id));
      if (this.searchParams.type === 0 || this.searchParams.type === 1)
        monsters = monsters.filter((monster) => monster.Grade == this.searchParams.type);
      if (this.searchParams.monsterClass)
        monsters = monsters.filter((monster) => monster.Class == this.searchParams.monsterClass);
      if (this.searchParams.race) monsters = monsters.filter((monster) => monster.Race == this.searchParams.race);

      this.filteredMonsters = monsters;
      this.totalFilteredMonster = monsters.length;
    }
    this.slicedMyMonsters = this.filteredMonsters.slice(
      (this.ownedMonsterPage - 1) * this.monstersPerPage,
      this.ownedMonsterPage * this.monstersPerPage
    );
    this.calculateTotalPage();
  }

  calculateTotalPage() {
    if (this.totalFilteredMonster! % this.monstersPerPage! == 0)
      this.totalOwnedMonsterPage = this.totalFilteredMonster! / this.monstersPerPage!;
    else this.totalOwnedMonsterPage = Math.floor(this.totalFilteredMonster! / this.monstersPerPage!) + 1;
  }

  @asyncAction *loadMyMonsters(selectingMonsterIds?: any[]) {
    this.loadingMonster = true;
    let nftIds: any[] = [];

    try {
      const response = yield moralisHelper.getAllAccountNFTs(
        walletStore.account,
        process.env.VUE_APP_CHAIN_ID === "97" ? "bsc testnet" : "bsc"
      );

      this.totalOwnedMonsters = response!.total!;
      this.myNFTs = response!.results! as Array<MoralisModel>;
      nftIds = this.myNFTs.map((moralisNFT) => moralisNFT.token_id);
      // cache nftIds
      localdata.setMonsterNftIds(walletStore.account, nftIds);
    } catch (error) {
      console.error(error);
      // get nftIds from cached
      nftIds = localdata.getMonsterNftIds(walletStore.account);
      this.totalOwnedMonsters = nftIds.length;
    }

    if (selectingMonsterIds && selectingMonsterIds.length) {
      nftIds = nftIds.filter((id) => !selectingMonsterIds.includes(id));
    }

    let myMonsters: NftModel[] = [];
    if (nftIds && nftIds.length > 0) {
      const groups = chunk(nftIds, 100);
      const characters: any[] = [];
      for (const ids of groups) {
        const nfts = yield appProvider.api.nft.find({
          // eslint-disable-next-line
          ID_in: ids,
          _sort: "Grade:desc",
          _limit: -1,
        });
        characters.push(...nfts);
      }

      myMonsters = characters as Array<NftModel>;
    }
    myMonsters = [...this.userLockedMonsters, ...myMonsters];
    this.totalOwnedMonsters += this.userLockedMonsters.length;
    this.setMyMonsters(myMonsters);
    this.loadingMonster = false;
  }

  async loadData() {
    const trainingGroundHandler = new TraningGroundHandler();
    this.trainingGroundHandler = trainingGroundHandler;
    loadingController.increaseRequest();
    try {
      this.fetchTokenPrice();
      await trainingGroundHandler.load();
      await this.fetchUserInfo();

      if (walletStore.account) {
        await this.getUserLockedNFTs();
        await this.loadMyMonsters();

        this.searchMyMonsters({});
      }

      this.getTotalMonstersInTraining();

      runInAction(() => {
        this.totalLockedAmount = trainingGroundHandler.lockedAmount || FixedNumber.from("0");
        this.totalValueLocked = this.totalLockedAmount.mulUnsafe(FixedNumber.from(this.tokenPrice));
      });
    } catch (error) {
      console.error("loadData", error);
    } finally {
      loadingController.decreaseRequest();
    }

    timer(0, 30000)
      .pipe(takeUntil(this._unsubcrible))
      .subscribe(async () => {
        const { lockedAmount } = await trainingGroundHandler.getTotalLockedAmount();
        runInAction(() => {
          this.totalLockedAmount = lockedAmount;
          this.totalValueLocked = this.totalLockedAmount.mulUnsafe(FixedNumber.from(this.tokenPrice));
        });
      });

    this._disposers.push(
      when(
        () => walletStore.connected,
        async () => {
          if (walletStore.chainId == process.env.VUE_APP_CHAIN_ID) {
            trainingGroundHandler.injectMetamask(walletStore.web3!);
            trainingGroundHandler.approved(walletStore.account).then((approved) =>
              runInAction(() => {
                this.approved = approved;
              })
            );
            trainingGroundHandler
              .approvedNFT(walletStore.account)
              .then((approved) => runInAction(() => (this.nftApproved = approved)));

            timer(0, 10000)
              .pipe(takeUntil(this._unsubcrible))
              .subscribe(() => {
                this.fetchTokenPrice();
                this.getTotalMonstersInTraining();
                this.fetchUserInfo();
              });
          }
        }
      )
    );
  }

  @asyncAction *fetchUserInfo() {
    if (!walletStore.account || !this.trainingGroundHandler) {
      this.stakedLP = FixedNumber.from("0");
    } else {
      const [
        {
          amount,
          lastStakeTime,
          rewardAmount,
          extraRewardAmount,
          lockDuration,
          normalNo,
          rareNo,
          lastRewardTime,
          userExtraBonus,
          userApy,
          userPendingReward,
        },
        userLPBalance,
      ] = yield Promise.all([
        this.trainingGroundHandler.getUserInfo(walletStore.account),
        this.trainingGroundHandler.getUserLPBalance(walletStore.account),
      ]);
      this.userLPBalance = userLPBalance;
      this.stakedLP = amount;
      this.lastStakeTime = lastStakeTime;
      this.userLockedDuration = lockDuration;
      this.userLockedNormalNo = normalNo;
      this.userLockedRareNo = rareNo;
      this.userExtraBonus = userExtraBonus;
      this.userApy = userApy;
      this.userPendingReward = userPendingReward;
    }
  }

  @asyncAction *getTotalMonstersInTraining() {
    this.totalLockedMonster = yield this.trainingGroundHandler!.getTotalLockedMonster();
  }

  @asyncAction *getUserLockedNFTs() {
    try {
      const nftIds = yield this.trainingGroundHandler!.getUserLockedNfts(walletStore.account);
      if (nftIds && nftIds.length > 0) {
        const groups = chunk(nftIds, 100);
        const characters: any[] = [];
        for (const ids of groups) {
          const nfts = yield appProvider.api.nft.find({
            // eslint-disable-next-line
            ID_in: ids,
            _limit: -1,
          });
          characters.push(...nfts);
        }
        this.userLockedMonsters = characters as Array<NftModel>;
      } else {
        this.userLockedMonsters = [];
      }
    } catch (error) {
      console.error(error);
      snackController.error(error);
    }
  }

  @asyncAction *approveNFT() {
    this.nftApproving = true;
    try {
      yield this.trainingGroundHandler!.approveNFT(walletStore.account);
      this.nftApproved = true;
      snackController.success("Approve successfully!");
    } catch (error) {
      snackController.error(error.message);
    } finally {
      this.nftApproving = false;
    }
  }

  @asyncAction *approve() {
    this.approving = true;
    try {
      yield this.trainingGroundHandler!.approve(walletStore.account);
      this.approved = true;
      snackController.success("Approve successfully!");
    } catch (error) {
      snackController.error(error.message);
    } finally {
      this.approving = false;
    }
  }
  @asyncAction *requestStake() {
    this.isStaking = true;
    loadingController.increaseRequest();
    try {
      yield this.trainingGroundHandler!.stake(
        walletStore.account,
        this.inputStakeAmount,
        moment.duration(this.currentLockPeriod.value, "day").asSeconds(),
        this.selectedMonsterIds
      );
      snackController.success("Stake sucessfully!");
      yield this.fetchUserInfo();
      yield this.getUserLockedNFTs();
    } catch (error) {
      snackController.error(error.message);
    } finally {
      loadingController.decreaseRequest();
      this.isStaking = false;
    }
  }
  @asyncAction *harvest() {
    this.isHarvesting = true;
    this.lastHarvestAmount = this.userPendingReward;
    try {
      yield this.trainingGroundHandler!.harvest(walletStore.account);
      yield this.fetchUserInfo();
      snackController.success("Harvest successfully!");
    } catch (error) {
      snackController.error(error.message || error.msg);
    } finally {
      this.isHarvesting = false;
    }
  }
  @asyncAction *increaseLockTime() {
    this.isDialogLoading = true;
    try {
      yield this.trainingGroundHandler!.increaseLockTime(
        walletStore.account,
        moment.duration(this.currentLockPeriod.value, "day").asSeconds()
      );
      yield this.fetchUserInfo();
      snackController.success("Increase lock period successfully!");
      this.increaseUnlockTimeDialog = false;
    } catch (error) {
      snackController.error(error.message || error.msg);
    } finally {
      this.isDialogLoading = false;
    }
  }
  @asyncAction *increaseStake() {
    this.isDialogLoading = true;
    try {
      yield this.trainingGroundHandler!.increaseStake(walletStore.account, this.inputStakeAmount, this.lockInSeconds);
      yield this.fetchUserInfo();
      this.inputStakeAmount = "0";
      snackController.success("Increase stake successfully!");
      this.increaseStakeDialog = false;
    } catch (error) {
      snackController.error(error.message || error.msg);
    } finally {
      this.isDialogLoading = false;
    }
  }
  @asyncAction *withdrawStake() {
    this.isDialogLoading = true;
    try {
      yield this.trainingGroundHandler!.withdrawStake(walletStore.account);
      yield this.fetchUserInfo();
      snackController.success("Withdraw stake successfully!");
      this.unstakeDialog = false;
    } catch (error) {
      snackController.error(error.message || error.msg);
    } finally {
      this.isDialogLoading = false;
    }
  }
  @asyncAction *withdrawNFT() {
    this.isDialogLoading = true;
    try {
      yield this.trainingGroundHandler!.withdrawNFTs(walletStore.account);
      yield this.fetchUserInfo();
      yield this.getUserLockedNFTs();
      snackController.success("Withdraw monsters successfully!");
      this.unstakeMonsterDialog = false;
    } catch (error) {
      snackController.error(error.message || error.msg);
    } finally {
      this.isDialogLoading = false;
    }
  }
  @asyncAction *lockSelectingMonsters() {
    loadingController.increaseRequest();
    try {
      yield Promise.race([
        this.trainingGroundHandler!.lockNFTs(walletStore.account, this.selectingMonsterIds),
        promiseHelper.delay(80000),
      ]);
      yield this.fetchUserInfo();
      yield this.getUserLockedNFTs();
      snackController.success("Lock monsters successfully!");
      this.selectMonsterDialog = false;
      return true;
    } catch (error) {
      snackController.error(error.message || error.msg);
      return false;
    } finally {
      loadingController.decreaseRequest();
    }
  }

  isSelectingMonster(id) {
    return Object.prototype.hasOwnProperty.call(this.selectingMonsterObj, id);
  }

  @action.bound setMyMonsters(monsters: NftModel[]) {
    this.myMonsters = monsters.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [`${currentValue.id}`]: currentValue,
      }),
      {}
    );
  }

  @action.bound setSelectingMonsters(monsters: NftModel[]) {
    this.selectingMonsterObj = monsters.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [`${currentValue.id}`]: currentValue,
      }),
      {}
    );
  }

  @action.bound toggleSelectMonster(id) {
    if (this.myMonsters[id]) {
      if (!this.selectingMonsterObj[id]) this.selectingMonsterObj[id] = this.myMonsters[id];
      else delete this.selectingMonsterObj[id];
    }
  }
  @action.bound setLockMonster(val) {
    return (this.isLockMonster = val);
  }
  @action.bound changeMyMonsterPage(page) {
    this.ownedMonsterPage = page;
    this.searchMyMonsters({ ...this.searchParams, page });
  }

  @action.bound fetchTokenPrice() {
    priceHelper.getTokenPrice().then(() => {
      runInAction(() => (this.tokenPrice = priceHelper.tokenPriceBUSD));
    });
  }
  @action.bound openChooseMonsterDialog() {
    this.selectMonsterDialog = true;
    if (this.isMonsterStaked) {
      this.setSelectingMonsters(this.userLockedMonsters);
    }
  }
  @action.bound closeChooseMonsterDialog() {
    this.selectMonsterDialog = false;
  }

  @action.bound openNoStakeMonsterDialog() {
    this.noStakeMonsterDialog = true;
  }
  @action.bound closeNoStakeMonsterDialog() {
    this.noStakeMonsterDialog = false;
    this.selectingMonsterObj = {};
  }
  @action.bound doneNoStakeMonsterDialog() {
    this.noStakeMonsterDialog = false;
    this.selectedMonsterObj = this.selectingMonsterObj;
    this.selectingMonsterObj = {};
    this.noStakeMonsterDone = true;
  }

  @action.bound removeNoStakeMonsterDone() {
    this.selectedMonsterObj = {};
    this.noStakeMonsterDone = false;
  }
  @action.bound changeInputAmount(amount) {
    if (numberHelper.isNumber(amount)) this.inputStakeAmount = "" + amount;
    else this.inputStakeAmount = "0";
  }

  @action.bound changeLockPeriod(val) {
    this.currentLockPeriod = StakeLockPeriods[val];
    this.selectedAPR = this.currentLockPeriod.apr;
    this.selectedLockPeriod = this.currentLockPeriod.lockPeriod;
  }

  @action.bound requestIncreaseLockTime() {
    this.increaseUnlockTimeDialog = true;
  }
  @action.bound closeUnlockTimeDialog() {
    this.increaseUnlockTimeDialog = false;
    this.currentLockPeriod = null;
  }
  @action.bound requestIncreaseStake() {
    this.increaseStakeDialog = true;
  }
  @action.bound closeIncreaseStake() {
    this.increaseStakeDialog = false;
    this.inputStakeAmount = "0";
  }
  @action.bound requestWithdrawStake() {
    this.unstakeDialog = true;
  }
  @action.bound closeWithdrawStake() {
    this.unstakeDialog = false;
  }
  @action.bound requestWithdrawNFTs() {
    this.unstakeMonsterDialog = true;
  }
  @action.bound closeWithdrawNFTs() {
    this.unstakeMonsterDialog = false;
  }

  @action.bound requestUnstakeLP() {
    this.unstakeDialog = true;
  }

  @computed get myMonsterArray() {
    return Object.values(this.myMonsters);
  }
  @computed get isStaked() {
    return bigNumberHelper.gt(this.stakedLP, FixedNumber.from("0"));
  }
  @computed get isMonsterStaked() {
    return this.userLockedMonsters && this.userLockedMonsters.length > 0;
  }

  @computed get lockInDays() {
    return this.userLockedDuration.asDays();
  }
  @computed get lockInSeconds() {
    return this.userLockedDuration.asSeconds();
  }
  @computed get canUnstakeTime() {
    if (this.lastStakeTime) {
      return this.lastStakeTime.clone().add(this.userLockedDuration);
    }
    return null;
  }
  @computed get canHarvest() {
    return bigNumberHelper.gt(this.userPendingReward, Zero);
  }
  @computed get canUnstake() {
    return moment().isAfter(this.canUnstakeTime);
  }
  @computed get lockedUntil() {
    return moment(this.lastStakeTime)
      .add(this.userLockedDuration)
      .format("DD/MM/YYYY HH:mm:ss");
  }
  @computed get selectingBoosterIndex() {
    const index = StakeBoosters.findIndex(
      (booster) =>
        booster.normal === this.selectingNormalMonsterCount && booster.rare === this.selectingRareMonsterCount
    );
    return index;
  }
  @computed get selectingBooster() {
    if (this.selectingBoosterIndex === -1) return StakeBoosters[StakeBoosters.length - 1];
    return StakeBoosters[this.selectingBoosterIndex];
  }

  @computed get selectedBoosterIndex() {
    return StakeBoosters.findIndex(
      (booster) => booster.normal === this.selectedNormalMonsterCount && booster.rare === this.selectedRareMonsterCount
    );
  }
  @computed get selectedBooster() {
    if (this.selectedBoosterIndex === -1) return StakeBoosters[StakeBoosters.length - 1];
    return StakeBoosters[this.selectedBoosterIndex];
  }
  @computed get estimatedInputValueUSD() {
    if (!this.inputStakeAmount || !this.tokenPrice) return FixedNumber.from("0");
    return FixedNumber.from(this.inputStakeAmount).mulUnsafe(FixedNumber.from(this.tokenPrice));
  }
  @computed get isValidStakeInput() {
    if (
      !this.inputStakeAmount ||
      FixedNumber.from(this.inputStakeAmount).isNegative() ||
      FixedNumber.from(this.inputStakeAmount).isZero()
    )
      return false;
    return bigNumberHelper.lte(FixedNumber.from(this.inputStakeAmount), this.userLPBalance);
  }

  //selected
  @computed get selectedMonsters() {
    if (this.isMonsterStaked) return this.userLockedMonsters;
    return Object.values(this.selectedMonsterObj);
  }
  @computed get selectedMonsterIds() {
    return this.selectedMonsters.map((monster) => (monster as NftModel).ID);
  }
  @computed get selectingMonsterIds() {
    return this.selectingMonsters.map((monster) => (monster as NftModel).ID);
  }
  @computed get selectedRareMonsterCount() {
    return this.noStakeMonsterDone
      ? this.selectedMonsters.filter((monster) => (monster as NftModel).Grade === 1).length
      : 0;
  }
  @computed get selectedNormalMonsterCount() {
    return this.noStakeMonsterDone ? this.selectedMonsters.length - this.selectedRareMonsterCount : 0;
  }

  //selecting
  @computed get selectingMonsters() {
    return Object.values(this.selectingMonsterObj);
  }
  @computed get isMaxSelectingMonster() {
    return this.selectingMonsters.length >= 5;
  }
  @computed get selectingRareMonsterCount() {
    return this.selectingMonsters.filter((monster) => (monster as NftModel).Grade === 1).length;
  }
  @computed get selectingNormalMonsterCount() {
    return this.selectingMonsters.length - this.selectingRareMonsterCount;
  }

  //current
  @computed get currentUserBooster() {
    return this.userExtraBonus.divUnsafe(FixedNumber.from("100")).addUnsafe(FixedNumber.from("1"));
  }
  @computed get currentUserBoosterIndex() {
    const result = StakeBoosters.findIndex(
      (booster) => booster.normal == this.userLockedNormalNo && booster.rare == this.userLockedRareNo
    );
    return result;
  }
  @computed get currentUserApy() {
    return this.userApy.mulUnsafe(this.currentUserBooster);
  }

  get selectedLockPeriodAsDays() {
    return this.currentLockPeriod ? moment.duration(this.currentLockPeriod.value, "day").asDays() : this.lockInDays;
  }
  get selectedLockPeriodAsSeconds() {
    return this.currentLockPeriod
      ? moment.duration(this.currentLockPeriod.value, "day").asSeconds()
      : this.lockInSeconds;
  }
  //#region estimated unlock time
  @computed get estimatedULUnlockTime() {
    return moment(this.lastStakeTime)
      .add(this.selectedLockPeriodAsDays, "days")
      .format("DD/MM/YYYY HH:mm:ss");
  }
  @computed get estimatedULFinalAPR() {
    return FixedNumber.from(this.selectedAPR + "").mulUnsafe(FixedNumber.from(this.currentUserBooster + ""));
  }
  @computed get estimatedULReward() {
    const estimated = this.stakedLP
      .divUnsafe(FixedNumber.from("100"))
      .mulUnsafe(FixedNumber.from(this.estimatedULFinalAPR + ""));
    return this.selectedLockPeriodAsDays == 0
      ? estimated
      : estimated.mulUnsafe(FixedNumber.from(this.selectedLockPeriodAsDays + "")).divUnsafe(FixedNumber.from("365"));
  }
  //#endregion

  //#region estimated stake
  @computed get estimatedStakeAmount() {
    return this.isValidStakeInput ? this.stakedLP.addUnsafe(FixedNumber.from(this.inputStakeAmount)) : this.stakedLP;
  }
  @computed get estimatedStakeReward() {
    const estimated = this.estimatedStakeAmount.divUnsafe(FixedNumber.from("100")).mulUnsafe(this.currentUserApy);
    return this.selectedLockPeriodAsDays === 0
      ? estimated
      : estimated.mulUnsafe(FixedNumber.from(this.selectedLockPeriodAsDays + "")).divUnsafe(FixedNumber.from("365"));
  }
  @computed get estimatedStakeLockTime() {
    return moment()
      .add(this.selectedLockPeriodAsDays, "days")
      .format("DD/MM/YYYY HH:mm:ss");
  }
  @computed get stakeLockTime() {
    return this.selectedLockPeriodAsDays === 0 ? "Non-term" : `${this.selectedLockPeriodAsDays} days`;
  }
  //#endregion

  @computed get getEstimatedSelectingBooster() {
    return this.selectingBooster.multiplier;
  }
  @computed get getEstimatedSelectedBooster() {
    return this.selectedBooster.multiplier;
  }
  @computed get getEstimatedFinalAPR() {
    return this.selectedAPR * this.getEstimatedSelectedBooster;
  }
  @computed get getEstimatedSelectingFinalAPR() {
    return this.selectedAPR * this.getEstimatedSelectingBooster;
  }
  @computed get getEstimatedAPR() {
    return this.userApy.mulUnsafe(FixedNumber.from(this.getEstimatedSelectingBooster + ""));
  }
  @computed get getEstimatedStake() {
    if (!this.inputStakeAmount) return FixedNumber.from("0");
    return this.isStaked
      ? this.stakedLP.addUnsafe(FixedNumber.from(this.inputStakeAmount))
      : FixedNumber.from(this.inputStakeAmount);
  }
  @computed get getEstimatedReward() {
    if (!this.selectedAPR || !this.currentLockPeriod) return 0;
    const estimated = this.getEstimatedStake
      .divUnsafe(FixedNumber.from("100"))
      .mulUnsafe(FixedNumber.from(this.getEstimatedFinalAPR + ""));
    return this.currentLockPeriod.value === 0
      ? estimated
      : estimated.mulUnsafe(FixedNumber.from(this.currentLockPeriod.value + "")).divUnsafe(FixedNumber.from("365"));
  }
  @computed get getEstimatedLockTimeUntil() {
    if (!this.currentLockPeriod) return "-/--";
    return this.isStaked
      ? this.lastStakeTime?.add(this.currentLockPeriod.value, "days").format("DD/MM/YYYY HH:mm:ss")
      : moment()
          .add(this.currentLockPeriod.value, "days")
          .format("DD/MM/YYYY HH:mm:ss");
  }
  @computed get selectedTimePeriodAsSeconds() {
    return !this.currentLockPeriod ? 0 : moment.duration(this.currentLockPeriod, "day").asDays() * 2;
  }

  @computed get validLockPeriods() {
    return Object.values(StakeLockPeriods).filter((period) => {
      if (this.isStaked && period.value > this.lockInDays) return true;
      else if (!this.isStaked && period.value >= this.lockInDays) return true;
      return false;
    });
  }
}
